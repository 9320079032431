var qsa = function(s) { return Array.prototype.slice.call(document.querySelectorAll(s)) };

const illustrations = qsa(".result-illustration");
const eligabilityCopy = qsa(".eligability-result");

const form = document.getElementById("calculator-form");
form.addEventListener("submit", submit_calculator);

const resultsContainer = qsa("#calculator-results")[0];

function submit_calculator(event) {
    event.preventDefault();
    var formData = Object.fromEntries(new FormData(event.target));

    var size = parseInt(formData["household-input"]);
    var income = parseInt(formData["household-income"]);
    var inGrades = formData["is-in-grade"];

    doCalculation(size, income, inGrades);
}

function doCalculation(size, income, inGrades) {
    if (size > 8 || size <=0 || income < 0)
        return;
    var reducedThreshold = thresholds.reduced[size - 1];
    var freeThreshold = thresholds.free[size - 1];

    resultsContainer.classList.remove("selected");
    resultsContainer.classList.add("selected");

    if (income <= freeThreshold)
        setEligabilityResults("free-illustration", "free-eligable");
    else if (income <= reducedThreshold)
        setEligabilityResults("reduced-illustration", "reduced-eligable");
    else
        setEligabilityResults("not-illustration", "not-eligable");

    setGradeCopy(inGrades === "true");
}

function setEligabilityResults(illustrationId, copyId) {
    illustrations.forEach(function(item, ix) {
        item.classList.remove("selected");
        if (item.id == illustrationId)
            item.classList.add("selected");
    });
    eligabilityCopy.forEach(function(item, ix) {
        item.classList.remove("selected");
        if (item.id == copyId)
            item.classList.add("selected");
    });
}

function setGradeCopy(inGrades) {
    var inGradeCopy = qsa("#in-grade-copy")[0];
    if (!!inGradeCopy) {
        inGradeCopy.classList.remove("selected");
        if (inGrades)
            inGradeCopy.classList.add("selected");
    }
}

const thresholds = {
    reduced: [
        25142,
        33874,
        42606,
        51338,
        60070,
        68802,
        77534,
        86266
    ],
    free: [
        17667,
        23803,
        29939,
        36075,
        42211,
        48347,
        54483,
        60619
    ]
}