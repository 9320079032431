
require("./calculator");
require("waypoints/lib/noframework.waypoints.min")

var qsa = function(s) { return Array.prototype.slice.call(document.querySelectorAll(s)) };

var images = qsa(".fixable");
var sections = qsa(".scrolling-section");
var fadeInItems = qsa(".fade-into-view");
var slideInItems = qsa(".slide-into-view");
var flourishEmbeds = qsa(".flourish-embed");
var beginning = qsa("#intro-copy")[0];
var scrolly = qsa(".scrolling-section")[0];
var headerVideo = qsa(".video-container")[0];
var indexed = [];

images.forEach(function(img, i) {
  var index = parseInt(img.getAttribute("data-index"));
  if (index !== undefined)
    indexed[index] = img;
});

flourishEmbeds.forEach(function(embed, i) {
  window.Flourish.loadEmbed(embed);
})

const introWP = new Waypoint({
  element: beginning,
  offset: "95%",
  handler: (direction) => {
    if (direction == "down") {
      headerVideo.classList.add("hidden");
    } else {
      headerVideo.classList.remove("hidden");
    }
  }
})

if (!!scrolly) {
  const hideVideo = new Waypoint({
    element: scrolly,
    handler: (direction) => {
      if (direction == "down") {
        headerVideo.classList.add("gone");
      } else {
        headerVideo.classList.remove("gone");
      }
    }
  })
}

var debounce = function(f, d) {
  d = d || 100;
  var timeout = null;
  return function() {
    var args = [];
    for (var i = 0; i < arguments.length; i++) {
      args[i] = arguments[i];
    }
    if (timeout) return;
    timeout = setTimeout(function() {
      timeout = null;
      f.apply(null, args);
    }, d);
  };
};

const visibleObs = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-visible');
    } else {
      entry.target.classList.remove('is-visible');
    }
  });
});

fadeInItems.forEach(item => {
  visibleObs.observe(item);
});
slideInItems.forEach(item => {
  visibleObs.observe(item);
});


window.addEventListener("scroll", debounce(() => {
  sections.forEach(section => {
    var bounds = section.getBoundingClientRect();
    var index = parseInt(section.getAttribute("data-index"));
    var img = indexed[index];
    if (bounds.top <= window.innerHeight && 
      (index == 5 || bounds.bottom + 50 >= window.innerHeight)) {
      img.classList.add("is-visible");
    } else {
      img.classList.remove("is-visible");
    }
  })
}));